export const appRoutes = {
  AUTH: 'auth',
  LOGIN: 'login',
  REGISTER: 'register',
  LINKEDIN_CALLBACK: 'linkedin-callback',
  DASHBOARD: 'dashboard',
  LISTS: 'lists',
  EMAIL_CONFIRMATION: 'confirm-account',
  LOGOUT: 'logout',
};
